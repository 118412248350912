.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0 32px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
}

.tripStatus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contentGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  & div:first-child {
    flex: 1;
  }

  & div:last-child {
    flex: 1;
  }
}

@media (max-width: 900px) {
  .contentGrid {
    flex-direction: column;
  }
}

.vehicle,
.driver {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  flex: 1 0 0;
}

.vehicleHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price {
  font-size: 1.4em;
  text-align: left;
  display: block;
  padding-left: 20px;
  margin-top: 10px;
  width: 100%;
}

.informationGrid {
  display: grid;
  width: 100%;
  gap: 15px;
  grid-template-areas: "from to" "date time" "passengers passengers" "divider divider" "name name" "email email" "phone phone" "divider1 divider1" "airport-pickup airport-pickup" "child-seats child-seats" "notes notes";
}

.status {
  font-size: 20px;
  font-weight: bold;
}

.information {
  display: flex;
  gap: 5px;
}

.information:nth-child(1) {
  grid-area: from;
}

.information:nth-child(2) {
  grid-area: to;
}

.information:nth-child(3) {
  grid-area: date;
}

.information:nth-child(4) {
  grid-area: time;
}

.information:nth-child(5) {
  grid-area: passengers;
}

.information:nth-child(6) {
  grid-area: divider;
}

.information:nth-child(7) {
  grid-area: name;
}

.information:nth-child(8) {
  grid-area: email;
}

.information:nth-child(9) {
  grid-area: phone;
}

.information:nth-child(10) {
  grid-area: divider1;
}

.information:nth-child(11) {
  grid-area: airport-pickup;
}

.information:nth-child(12) {
  grid-area: child-seats;
}

.information:nth-child(13) {
  grid-area: notes;
}

.tripInformation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.subtitle {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.informationRow {
  display: flex;
  gap: 20px;
}

.description {
  font-weight: bold;
}

.map {
  width: 100%;
  height: 750px;
  margin: 30px 0;
}

@media (min-width: 1080px) {
  .header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 110px;
  }

  .contentGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "trip-information driver" "trip-information vehicle";
  }
}

.priceContainer {
  display: flex;
  flex-direction: row;

  & > div:last-child {
    margin-top: 30px;
  }
}
