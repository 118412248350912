.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto auto;
  gap: 50px;
}

.informationDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #f3f3f3;

  h2,
  h3 {
    margin: 0;
  }
}

h3.duration {
  font-weight: normal;
  line-height: 1em;
  font-size: 0.9em;
  font-style: italic;
}

.formStructure {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dateTimePassengers {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex: 1;
}

.date {
  max-width: 190px;
  width: 100%;
}

.passengers {
  max-width: 75px;
  width: 100%;
}

.contact {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 60px;
}

.extraInfoInput {
  display: flex;
  align-items: center;
  height: 36px;
}

.extraInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.childInfo {
  max-width: 150px;
  width: 100%;
}

.vehicle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media screen and (max-width: 840px) {
  .container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 440px) {
  .dateTimePassengers {
    flex-wrap: wrap;
  }

  .contact {
    flex-direction: column;
  }
}
