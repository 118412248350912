.wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  padding: 20px;
  gap: 20px;
  margin: 0 auto;
}

.map {
  display: flex;
  position: relative;
  height: 750px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 0;
  gap: 20px;
}

.stopsContainer {
  width: 100%;
  max-width: 600px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 20px;
  border-radius: 8px;
}

.googleInput {
  min-width: 150px;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #ced4da;
  outline: none;
}

.inputContainer {
  min-width: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.stopRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.iconsContainer {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 2px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

@media (min-width: 850px) {
  .formContainer {
    flex-direction: row;
    align-items: flex-end;
  }

  .stopsContainer,
  .buttonsContainer {
    width: 50%;
  }
}

@media (min-width: 450px) {
  .stopRow {
    flex-direction: row;
    align-items: center;
  }
}
