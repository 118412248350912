.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
}

.wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  padding: 32px;
  gap: 20px;
  margin: 0 auto;
}

.dataDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.scrollContainer {
  width: 100%;
}

.map {
  display: flex;
  position: relative;
  height: 750px;
}

.pois {
  margin-bottom: 20px;
}

.container {
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dataColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.title {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  line-height: 22px;
}

.informationDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.vehicleButtons {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.subtitle {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.formStructure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.inputForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.datePassengers {
  display: flex;
  gap: 12px;
}

.datetime {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  min-width: 150px;
  max-width: 180px;
  width: 100%;
}

.timePicker {
  width: 100%;
}

.passengers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 90px;
}

.distance {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.contract {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}

.vehicle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  flex: 1 0 0;
}

.vehicleHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.otherVehicle {
  color: #1c7ed6;
  font-weight: 700;
  line-height: 16px;
  font-size: 14px;
}

.upgradeContainer {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
}

.upgradeDescription {
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #495057;
}

.upgradeLink {
  color: #1c7ed6;
  font-weight: 700;
  line-height: 16px;
  font-size: 12px;
}

.extraInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.textarea {
  width: 100%;
}

.extraInfoInput {
  width: 315px;
}

.googleInput {
  min-width: 150px;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #ced4da;
  outline: none;
}

.googleInput:focus {
  border: 1px solid #228be6;
}

.customerInterest {
  width: 100%;
}

@media screen and (min-width: 622px) {
  .inputForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    width: 100%;
  }

  .contract {
    flex-direction: row;
  }

  .extraInfoInput {
    width: 60%;
  }

  .textarea {
    width: 100%;
  }
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.selectedPois {
  margin-top: 20px;
}

.m_39bc3463:where([data-orientation="horizontal"]) .mantine-Carousel-controls {
  top: 100px !important;
}

@media screen and (min-width: 750px) {
  .dataColumn {
    flex-direction: row;
  }

  .informationDetails,
  .vehicleButtons {
    width: 50%;
  }
}

@media screen and (min-width: 1275px) {
  .wrap {
    flex-direction: row;
    padding: 32px;
  }

  .container {
    width: 66%;
  }

  .scrollContainer {
    width: 33%;
  }

  .dataDisplay {
    flex: 1;
    align-items: flex-start;
  }

  .map {
    flex: 1;
  }
}

@media screen and (min-width: 1865px) {
  .formStructure {
    flex-direction: row;
  }
}
