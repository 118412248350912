.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  p {
    margin: 0;
  }
}

.driverName {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
}

.driverDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 5px;
}

.driverDetails {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #495057;
  gap: 5px;
}

@media (min-width: 622px) {
  .container {
    flex-direction: row;
  }

  .description {
    align-items: flex-start;
  }
}
